import React from 'react';
import { useTranslation } from 'next-i18next';
import { useAdvertising } from '@/services/advertising';
import { Section } from '@/components/molecules';
import { Advertising } from '@/components/organisms';

const AdvertisingSection: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const { advertising } = useAdvertising();

    if (advertising?.length === 0) return null;

    return (
        <Section id="whats-new" title={t('advertising:section.title')} description={t('advertising:section.description')} bgcolor="white">
            <Advertising advertising={advertising} />
        </Section>
    );
};

export default AdvertisingSection;
