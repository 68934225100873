import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { PACKAGES_QUERY, PACKAGE_BY_ID_QUERY } from '@/graphql/queries';
import { PackageByIdQuery, PackageByIdQueryVariables, PackagesQuery, TouristPackageType } from '@/graphql/types';
import { useSessionCookie } from '@/modules/session-cookie';

export const usePackages = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<PackagesQuery>(PACKAGES_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { packages: data?.packages?.objects as TouristPackageType[], loading, error };
};

export const usePackagesById = (id: string) => {
    const { i18n } = useTranslation(); // Translations
    const { sessionID } = useSessionCookie();

    const { data, loading, error, client } = useQuery<PackageByIdQuery, PackageByIdQueryVariables>(PACKAGE_BY_ID_QUERY, {
        variables: { id },
        context: {
            headers: {
                'X-Language': i18n.language,
                'X-Session-Id': sessionID,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const dataOnCache = client.readQuery({
        query: PACKAGE_BY_ID_QUERY,
        variables: {
            id,
        },
    });

    return {
        packageById: data?.packageById?.object,
        status: data?.packageById?.status,
        loading,
        error,
        dataOnCache,
    };
};
