import React from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { Icon, Typography } from '@/components/atoms';
import { Section } from '@/components/molecules';
import { TabType } from '@/components/molecules/Tab';
import { FiltersTabs } from '@/components/organisms';
import { neutral } from '@/styles/colors';

const FlightsForm = dynamic(() => import('@/forms/flights-form'), {
    ssr: false,
});

const SearchSection: React.FC = () => {
    const { t } = useTranslation();

    const tabs: TabType[] = [
        {
            key: 'flights',
            icon: <Icon.Plane size={28} color={neutral.neutral10} />,
            title: t('filters:tab.flights.title'),
            content: <FlightsForm />,
        },
        {
            key: 'hotels',
            icon: <Icon.Hotel3 size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.hotels.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'flight-hotels',
            icon: <Icon.Hotel2 size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.flight-hotels.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'cars',
            icon: <Icon.Car2 size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.cars.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'activities',
            icon: <Icon.Target2 size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.activities.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'insurances',
            icon: <Icon.Insurance size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.insurances.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'packages',
            icon: <Icon.Package size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.packages.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
        {
            key: 'custom-travel',
            icon: <Icon.Puzzle size={28} stroke={neutral.neutral10} strokeWidth={2} />,
            title: t('filters:tab.custom-travel.title'),
            content: (
                <div style={{ padding: '70px', height: '199px' }}>
                    <Typography variant="h6">{t('common:coming-soon')}</Typography>
                </div>
            ),
        },
    ];

    return (
        <Section
            id="search"
            container="oversized"
            bgcolor="transparent"
            fadeInTransition={false}
            style={{
                padding: '0',
                marginTop: '-68px',
            }}
        >
            <FiltersTabs tabs={tabs} />
        </Section>
    );
};

export default SearchSection;
