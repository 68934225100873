import React from 'react';
import * as FeatherIcon from 'react-feather';
import { useTranslation } from 'next-i18next';
import UrlMapper from '@/config/url-mapper';
import { Section } from '@/components/molecules';
import { Banner } from '@/components/organisms';
import { primary } from '@/styles/colors';

interface BannerSectionProps {
    type: 'contact' | 'promotion';
}

const BannerSection: React.FC<BannerSectionProps> = ({ type }) => {
    const { t } = useTranslation(); // Translations

    const banners = {
        contact: {
            image: {
                url: '/images/banners/contact.jpg',
                alt: t('banners:banner1.title'),
            },
            title: t('banners:banner1.title'),
            description: t('banners:banner1.description'),
            buttonIcon: <FeatherIcon.Headphones size={24} color={primary.primary10} strokeWidth={2} />,
            buttonText: t('banners:banner1.button_text'),
            buttonLink: UrlMapper.whatsapp_agents,
        },
        promotion: {
            image: {
                url: '/images/banners/promotion.jpg',
                alt: t('banners:banner2.title'),
            },
            title: t('banners:banner2.title'),
            description: t('banners:banner2.description'),
            buttonText: t('banners:banner2.button_text'),
            buttonLink: UrlMapper.whatsapp_agents,
        },
    };

    return (
        <Section id={`banner-${type}`} bgcolor="white">
            <Banner type={type} data={banners[type]} />
        </Section>
    );
};

export default BannerSection;
