import React from 'react';
import { useTranslation } from 'next-i18next';
import { useSession } from 'next-auth/react';
import { Section } from '@/components/molecules';
import NewslettersForm from '@/forms/newsletters-form';

const NewslettersSection: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const { data: session } = useSession(); // Get current session
    const user = session?.user;

    if (user?.isSubscribed) return null;

    return (
        <Section id="newsletters" title={t('newsletter:section.title')} description={t('newsletter:section.description')} bgcolor="pastel" container="compacted">
            <NewslettersForm type="section" />
        </Section>
    );
};

export default NewslettersSection;
