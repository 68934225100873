import { Section } from '@/components/molecules';
import { Faqs } from '@/components/organisms';
import { useFaqs } from '@/services/faqs';
import { useTranslation } from 'next-i18next';
import React from 'react';

const FaqSection: React.FC<{ color?: 'default' | 'inverted' }> = ({ color = 'default' }) => {
    const { t } = useTranslation(); // Translations
    const { faqs } = useFaqs();

    if (faqs?.length === 0) return null;

    return (
        <Section id="faqs" title={t('faqs:section.title')} bgcolor={color !== 'inverted' ? 'transparent' : 'default'} pb="48px" container="compacted">
            <Faqs items={faqs} bgColor={color} />
        </Section>
    );
};

export default FaqSection;
