import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { AdvertisingQuery, AdvertisingType } from "@/graphql/types";
import { ADVERTISING_QUERY } from "@/graphql/queries";

export const useAdvertising = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<AdvertisingQuery>(ADVERTISING_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { "X-Language": i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { advertising: data?.advertisings?.objects as AdvertisingType[], loading, error };
};
