import React from 'react';
import { Section } from '@/components/molecules';
import { useTranslation } from 'next-i18next';
import { useDestinationsMostPopulars } from '@/services/destinations';
import { FeaturedDestinations } from '@/components/organisms';

const PopularDestinationSection: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const { destinations } = useDestinationsMostPopulars();

    if (destinations?.length === 0) return null;

    return (
        <Section id="popular-destinations" title={t('destinations:popular.section.title')} description={t('destinations:popular.section.text')} bgcolor="white" tb="6px">
            <FeaturedDestinations destinations={destinations} />
        </Section>
    );
};

export default PopularDestinationSection;
