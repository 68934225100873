import React from 'react';
import { useTranslation } from 'next-i18next';
import { Section } from '@/components/molecules';
import { Advantages } from '@/components/organisms';

import Party from '@/assets/images/advantages/party.svg';
import Percent from '@/assets/images/advantages/percent.svg';
import Heart from '@/assets/images/advantages/heart.svg';
import Zap from '@/assets/images/advantages/zap.svg';

const AdvantagesSection: React.FC = () => {
    const { t } = useTranslation(); // Translations

    const advantages = [
        {
            key: 'advantage1',
            icon: <Party />,
            title: t('advantages:advantage1.title'),
            text: t('advantages:advantage1.text'),
        },
        {
            key: 'advantage2',
            icon: <Percent />,
            title: t('advantages:advantage2.title'),
            text: t('advantages:advantage2.text'),
        },
        {
            key: 'advantage3',
            icon: <Zap />,
            title: t('advantages:advantage3.title'),
            text: t('advantages:advantage3.text'),
        },
        {
            key: 'advantage4',
            icon: <Heart />,
            title: t('advantages:advantage4.title'),
            text: t('advantages:advantage4.text'),
        },
    ];

    return (
        <Section id="advantages" title={t('advantages:section.title')} bgcolor="white">
            <Advantages cards={advantages} />
        </Section>
    );
};

export default AdvantagesSection;
