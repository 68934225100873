import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { FAQS_QUERY } from '@/graphql/queries';
import { FaqsQuery, FaqType } from "@/graphql/types";

export const useFaqs = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<FaqsQuery>(FAQS_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { "X-Language": i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { faqs: data?.faqs?.objects as FaqType[], loading, error };
};
