import React from 'react';
import { Section } from '@/components/molecules';
import { useTranslation } from 'next-i18next';
import { usePackages } from "@/services/packages";
import { ExperienceSlider } from "@/components/organisms";

const NewExperiencesSection: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const { packages } = usePackages();

    if (packages?.length === 0) return null;

    return (
        <Section id="new-experiences" title={t('packages:home-new.experiences')} bgcolor="white" pb="0">
            <ExperienceSlider packages={packages} />
        </Section>
    );
};

export default NewExperiencesSection;
