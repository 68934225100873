import React from 'react';
import { NextSeo } from 'next-seo';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { redirectToUnderConstructionPage } from '@/utils/rewrites';
import { Layout, MainLayout } from '@/components/templates';
import { NextPageWithLayout } from '@/types';
import { initialSteps, WizardProvider } from '@/modules/wizard';
import CarouselSection from '@/sections/carousel';
import AdvantagesSection from '@/sections/advantages';
import BannerSection from '@/sections/banner';
import ContactFormSection from '@/sections/contact-form';
import FaqSection from '@/sections/faqs';
import PopularDestinationSection from '@/sections/popular';
import NewsletterSection from '@/sections/newsletters';
import NewExperiencesSection from '@/sections/new-experiences';
import AdvertisingSection from '@/sections/advertising';
import SearchSection from '@/sections/search';

const Homepage: NextPageWithLayout = () => {
    const { t } = useTranslation(); // Translations

    return (
        <>
            <NextSeo title={t('seo:homepage.title')} titleTemplate="%s" />

            <WizardProvider initialSteps={initialSteps}>
                <CarouselSection section="homepage" />

                <SearchSection />

                <AdvantagesSection />

                <PopularDestinationSection />

                <BannerSection type="contact" />

                <AdvertisingSection />

                <BannerSection type="promotion" />

                <NewExperiencesSection />

                <FaqSection />

                <NewsletterSection />

                <ContactFormSection />
            </WizardProvider>
        </>
    );
};

Homepage.getLayout = (page: React.ReactElement) => {
    return (
        <Layout>
            <MainLayout>{page}</MainLayout>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    const rules = redirectToUnderConstructionPage();

    const translations = await serverSideTranslations(context?.locale as string, [
        'common',
        'seo',
        'carousel',
        'footer',
        'navigations',
        'user-menu',
        'advantages',
        'advertising',
        'faqs',
        'newsletter',
        'banners',
        'contact',
        'packages',
        'destinations',
        'filters',
    ]);

    return {
        ...rules,
        props: {
            ...translations,
        },
    };
};

export default Homepage;
